class TimelineManager {
    constructor(timeline) {
        this.timeline = timeline;
    }

    getTotalFrames() {
        return Object.keys(this.timeline['frames']).length;
    }

    getFrames(frame) {
        return this.timeline['frames'] || null;
    }

    getFrame(frame) {
        return this.timeline['frames'][frame] || null;
    }

    haveFrame(frame) {
        return frame !== null || this.getFrame(frame) ? true : false;
    }

    getImages(frame) {
        const frameData = this.getFrame(frame);
        return frameData ? frameData['images'] || null : null;
    }

    getImageVariation(frame, variation) {
        const imagesData = this.getImages(frame);
        return imagesData ? imagesData[variation] || null : null;
    }

    getTrackers(frame) {
        const frameData = this.getFrame(frame);
        return frameData ? frameData['trackers'] || null : null;
    }
    
    getTrackersAsText(frame) {
        const trackerData = this.getTrackers(frame);
        const results = [];

        if(!trackerData){
            return [`No tracking data in frame ${frame}.`]
        }

        Object.keys(trackerData).forEach((key) => {
            const details = trackerData[key];
            results.push(`Tracker (${key}) : x = ${details.x}, y = ${details.y}, radius = ${details.radius}.`)
        });

        return results;
    }

    getTrackerTarget(frame, target) {
        const trackerData = this.getTrackers(frame);
        return trackerData ? trackerData[target] || null : null;
    }
}

export default TimelineManager;