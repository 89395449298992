import React, { useRef, useEffect, useState } from 'react';
import style from './landingPage.module.css';
import { handraiser, interior } from '../components/Images';
import HeroImg from '../components/HeroImg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';
import { useGSAP } from '@gsap/react';
import FloatingHandraiser from '../components/FloatingHandraiser';
import Drawer from '../components/Drawer';
import Specs from '../components/Specs';
import Slider from '../components/Slider';
import Hero from '../components/Interaction/Hero';
import Disclaimer from '../components/Disclaimer';
import SubHero from '../components/SubHero';
import { useInView } from 'react-intersection-observer';
import { triggerMKSEvent } from '../utilities/analyticsUtils';

gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(Observer);

const LandingPage = ({ loading, setOpenHandraiser, deeplinkOpen }) => {
  const gsapContainer = useRef();
  const [handTL, setHandTL] = useState('');
  const [spinnerTL, setSpinnerTL] = useState('');
  const [sliderTL, setSliderTl] = useState('');
  const [heroTL, setHeroTL] = useState('');
  const [showHandraiser, setShowHandraiser] = useState(false);

  const [clickedVidOne, setClickedVidOne] = useState(false);
  const [clickedVidTwo, setClickedVidTwo] = useState(false);
  // Scroll MKS
  const mksBaseEvent = {
    interactionType: 'scroll',
    locationInPage: '2025-kicks-reveal'
  };
  const handleScrollMks = (isInView, mksValue) => {
    if (isInView) {
      triggerMKSEvent('trackClickInteraction', {
        ...mksBaseEvent,
        interactionValue: mksValue
      });
    }
  };

  const [marqueeRef] = useInView({
    onChange: (inView) => {
      handleScrollMks(inView, 'Dual Dash (1/5)');
    },
    triggerOnce: true,
    threshold: 0
  });

  const [spotifyRef] = useInView({
    onChange: (inView) => {
      handleScrollMks(inView, 'Spotify (5/5)');
    },
    triggerOnce: true,
    threshold: 0
  });

  const [handraiserRef] = useInView({
    onChange: (inView) => {
      handleScrollMks(inView, 'Handraiser (4/5)');
    },
    triggerOnce: true,
    threshold: 0
  });

  useEffect(() => {
    window.focus();
    let iframe = document.getElementById('iframe');
    iframe.addEventListener('mouseover', function (e) {
      if (document.activeElement !== document.getElementById('iframe')) {
        window.focus();
      }
    });

    let highsnobIframe = document.getElementById('highsnobiety');
    highsnobIframe.addEventListener('mouseover', function (e) {
      if (document.activeElement !== document.getElementById('highsnobiety')) {
        window.focus();
      }
    });

    window.addEventListener('blur', function (e) {
      if (document.activeElement == document.getElementById('highsnobiety') && !clickedVidOne) {
        setClickedVidOne(true);
        triggerMKSEvent('trackClickInteraction', {
          interactionType: 'video',
          locationInPage: 'highsnobiety',
          interactionName: 'highsnobiety',
          interactionValue: 'play'
        });
      } else if (document.activeElement == document.getElementById('iframe') && !clickedVidTwo) {
        setClickedVidTwo(true);
        triggerMKSEvent('trackClickInteraction', {
          interactionType: 'video',
          locationInPage: 'highsnobiety',
          interactionName: 'KenLee',
          interactionValue: 'play'
        });
      }
    });
  }, []);
  const vibeRef = useRef();

  useGSAP(
    () => {
      let heroTL = gsap.timeline({
        scrollTrigger: {
          trigger: '.heroPin',
          start: `top top`,
          end: `bottom+=600px top`,
          pin: '.heroPin',
          toggleActions: 'play reverse play reverse'
        }
      });
      setHeroTL(heroTL);

      let mm = gsap.matchMedia();

      mm.add('(min-width: 1024px)', () => {
        const vibeRect = vibeRef.current.getBoundingClientRect();
        gsap.set(`.${style.titledPargragh}`, {
          width: vibeRect.width,
          position: 'absolute'
        });
        gsap.to(`.${style.frame}`, {
          flexBasis: '100%',
          scrollTrigger: {
            trigger: `.${style.vibesContainer}`,
            start: 'top 5%',
            end: '+=1000px',
            scrub: true,
            pin: `.${style.vibesContainer}`,
            ease: ''
          }
        });
        gsap.to(`.${style.titledPargragh}`, {
          opacity: 0,
          duration: 3,
          scrollTrigger: {
            trigger: `.${style.vibesContainer}`,
            start: 'top 10%',
            end: 'bottom 20%',
            scrub: true
          }
        });
      });

      mm.add('(max-width: 768px)', () => {
        gsap.to(`.dualDisplay`, {
          objectPosition: '0% center',
          scrollTrigger: {
            trigger: `.${style.frame}`,
            start: 'top center',
            end: 'bottom center+=54px',
            ease: 'power4.in',
            toggleActions: 'play reverse play reverse',
            scrub: true
          }
        });
      });

      let spinnerTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#specs',
          start: `top center`,
          end: `bottom bottom`
        }
      });

      setSpinnerTL(spinnerTL);

      let newTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#handRaiser',
          start: `top top`,
          end: `top top`,
          onUpdate: (self) => setShowHandraiser(false),
          onEnterBack: (self) => setShowHandraiser(true),
        }
      });
      setHandTL(newTL);
    },
    { scope: gsapContainer }
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <div ref={gsapContainer}>
        <div className="heroPin">
          <Hero
            parentTl={heroTL}
            showHandraiser={setShowHandraiser}
            loading={loading}
            setDisplayOnLoad={setOpenHandraiser}
            deepLinkOpen={deeplinkOpen}
            testTL={heroTL}
          />
        </div>
        {showHandraiser ? <FloatingHandraiser loading={loading} /> : null}

        <div className={style.topContainer}>
          <HeroImg />
          <SubHero />
          <div className={`${style.vibes}`} ref={vibeRef} id="connectedTech">
            <div className={`${style.vibesContainer}`} ref={marqueeRef}>
              <div className={`${style.titledPargragh} title`}>
                <h3>
                  IN-SYNC.
                  <br />
                  DUAL-SCREEN.
                  <br />
                  CHARGED-UP.
                  <br />
                </h3>
                <p className={style.vibesPara}>
                  Step up to available tech that will make your friends —and your compatible
                  smartphone — jealous. Kicks has a seamless dual display and an innovative audio
                  system that surrounds you in sound. And with wireless connectivity, you can kiss
                  those cords goodbye.
                  <Disclaimer disclaimerCode={48} />
                </p>
              </div>
              <div className={`${style.frame}`}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    left: 0,
                    width: 32,
                    height: 32,
                    background: '#ffffff75',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'black',
                    zIndex: 1000,
                    borderBottomLeftRadius: '10px'
                  }}>
                  <Disclaimer
                    disclaimerCode={291}
                    black
                    top={window.innerWidth > window.innerHeight ? true : false}
                    topRight={window.innerWidth < window.innerHeight ? true : false}
                  />
                </div>
                <img src={interior} alt="2025 Nissan Kicks Dual Screen" className="dualDisplay" />
              </div>
            </div>
            <Drawer />
          </div>

          <Slider timeline={sliderTL} />
          <Specs timeLine={spinnerTL} />
        </div>

        <div className={style.youtubeContainer} id="designVideos">
          <div className={style.youtubeRails}>
            <div className={style.videoArea}>
              <iframe
                id="highsnobiety"
                className={style.youtubeVideo}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/EYFExn-S5uM?si=DJRydh9goo0InUrv&origin=https://2025-nissan-kicks.lihdigital.com/"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
              <h2>IT’S A WHOLE NEW KICKS</h2>
              <div className={style.titledPargraghSpotify}>
                <p className="spotify-intro">Get ready to fall in love at first sight</p>
                <p className={style.youtubeParagraph}>
                  The All-New Nissan Kicks is an ode to the community, incorporating design
                  principles and empowering self-expression that have made sneakers a universal hit.
                </p>
              </div>
            </div>

            <div className={style.highsnob}>
              <iframe
                id="iframe"
                className={style.youtubeVideo}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/4UqzV_IhtsQ?si=NMTQgEZZWGyZFGKc&origin=https://2025-nissan-kicks.lihdigital.com/"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
              <div className={style.videoArea}>
                <h2>KEN LEE TALKS KICKS DESIGN</h2>
                <div className={style.titledPargraghSpotify}>
                  <p className="spotify-intro">Brought to you by Highsnobiety</p>
                  <p className={style.youtubeParagraph}>
                    Join Ken Lee, Senior Designer Director at Nissan, for a look at what inspired
                    Kicks’ iconic new design.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="handRaiser"
          ref={handraiserRef}
          className={style.inlineHandRaiser}
          style={{ backgroundImage: `url(${handraiser})`, position: 'relative' }}>
          <div className={style.handraiserFrame}>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: 32,
                height: 32,
                background: '#ffffff75',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000
              }}>
              <Disclaimer disclaimerCode={291} black topRight />
            </div>
          </div>
          <div className={style.handraiserWrapper}>
            <div className={style.handraiserForm}>
              <div className={style.handraiserInfo}>
                <h2>TELL ME MORE</h2>
                <p>
                  Drop your details to stay up to date on the launch of the All-New Nissan Kicks.
                </p>
              </div>

              <iframe
                title="Hand Raiser Form"
                src=""
                className={style.paceIframe}
                loading="lazy">
              </iframe>


            </div>
          </div>
        </div>

        <div ref={spotifyRef} className={style.spotifyAligner} id="spotify">
          <div className={style.spotifyContainer}>
            <div className={style.sectionInfo}>
              <h2>
                NEW KICKS,
                <br />
                NEW SOUNDS
              </h2>
              <div className={style.titledPargraghSpotify}>
                <p className="spotify-intro">
                  With a new Bose Personal® Plus audio system, music hits different in the All-New
                  Nissan Kicks.
                </p>
                <p>
                  For the ultimate Kicks listening experience, Nissan has curated a KICKSTAPE
                  playlist featuring music tracks and picks from DJs Dope Candi, HB and KENDOLLAZ.
                </p>
              </div>
            </div>

            <iframe
              ref={spotifyRef}
              title="spotify playlist"
              className={style.spotifyIframe}
              style={{ borderRadius: '12px' }}
              src="https://open.spotify.com/embed/playlist/4q9cw3nSiAsHxO8jhkI19j?utm_source=generator&theme=0"
              width="585"
              height="400"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
