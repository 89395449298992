import React from 'react';
import style from './style.module.css';
import { bose, saftey, seats } from '../Images';
import Slide from './Slide';
import { useInView } from 'react-intersection-observer';
import { triggerMKSEvent } from '../../utilities/analyticsUtils';

const Slider = ({ timeline }) => {

  const [ sliderRef ] = useInView({
    onChange: (inView) => { 
      if (inView) {
        triggerMKSEvent('trackClickInteraction', {
          interactionType: 'scroll',
          interactionValue: 'Stadium Sound (2/5)',
          locationInPage: '2025-kicks-reveal'
        })
      }
    },
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div ref={sliderRef} className={`${style.sliderContainer} sliderContainer`}>
      <Slide
        title={'STADIUM<br />SOUND'}
        subTitle={'Bose Personal® Plus Audio System'}
        paragraph={`Designed with up to 10 strategically placed speakers that will make you and your passengers feel like you're listening to live music. Bose brings the power of sound to every seat with speakers built into the driver's and front-passenger's headrests.`}
        image={bose}
        slider={'slider1'}
        disclaimer={[233]}
        altText={'2025 Nissan Kicks Bose Audio system'}
      />
      <Slide
        title={'BRING<br />THE HEAT'}
        subTitle={'Heated Front Seats and Steering Wheel'}
        paragraph={`Conquer cold days and enjoy your drive with extra comfort. With the available heated front seats and heated steering wheel, you’ll stay cozy enough that your gloves can actually see the inside of a glove box.`}
        image={seats}
        slider={'slider2'}
        altText={'2025 Nissan Kicks heated steering wheel'}
      />
      <Slide
        title={'ALL-STAR<br />DEFENSE'}
        subTitle={'Safety Shield 360'}
        paragraph={`With standard Nissan Safety Shield® 360, Kicks can give added peace of mind you can enjoy mile after mile.`}
        image={saftey}
        slider={'slider3'}
        altText={'2025 Nissan Kicks grey'}
        disclaimer={[1176, 1117, 291]}
        seconPara={'And with the available ProPILOT Assist system and the Intelligent Around View® Monitor, you’ll find a new feeling of confidence — whether it’s during your daily highway commute or when you nab that parallel parking space.'}
        midtextDisclaimer={1381}
      />
    </div>
  );
};

export default Slider;
