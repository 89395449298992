// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(html) {
  --width-xxs: 240px;
  --width-xs: 360px;
  --width-sm: 480px;
  --width-md: 768px;
  --width-lg: 1200px;
  /* --width-lg: 1024px; */
  --width-xl: 1440px;
  --width-xxl: 1920px;
}`, "",{"version":3,"sources":["webpack://./public/css/props.widths.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[":where(html) {\n  --width-xxs: 240px;\n  --width-xs: 360px;\n  --width-sm: 480px;\n  --width-md: 768px;\n  --width-lg: 1200px;\n  /* --width-lg: 1024px; */\n  --width-xl: 1440px;\n  --width-xxl: 1920px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
