// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(html) {
  --border-size-1: 1px;
  --border-size-2: 2px;
  --border-size-3: 5px;
  --border-size-4: 10px;
  --border-size-5: 25px;
  --radius-1: 2px;
  --radius-2: 5px;
  --radius-3: 1rem;
  --radius-4: 2rem;
  --radius-5: 4rem;
  --radius-6: 8rem;
  --radius-round: 1e5px;
  --radius-blob-1: 30% 70% 70% 30% / 53% 30% 70% 47%;
  --radius-blob-2: 53% 47% 34% 66% / 63% 46% 54% 37%;
  --radius-blob-3: 37% 63% 56% 44% / 49% 56% 44% 51%;
  --radius-blob-4: 63% 37% 37% 63% / 43% 37% 63% 57%;
  --radius-blob-5: 49% 51% 48% 52% / 57% 44% 56% 43%;
  --radius-conditional-1: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-1));
  --radius-conditional-2: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-2));
  --radius-conditional-3: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-3));
  --radius-conditional-4: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-4));
  --radius-conditional-5: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-5));
  --radius-conditional-6: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-6));
}
`, "",{"version":3,"sources":["webpack://./public/css/props.borders.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,kDAAkD;EAClD,kDAAkD;EAClD,kDAAkD;EAClD,kDAAkD;EAClD,kDAAkD;EAClD,6EAA6E;EAC7E,6EAA6E;EAC7E,6EAA6E;EAC7E,6EAA6E;EAC7E,6EAA6E;EAC7E,6EAA6E;AAC/E","sourcesContent":[":where(html) {\n  --border-size-1: 1px;\n  --border-size-2: 2px;\n  --border-size-3: 5px;\n  --border-size-4: 10px;\n  --border-size-5: 25px;\n  --radius-1: 2px;\n  --radius-2: 5px;\n  --radius-3: 1rem;\n  --radius-4: 2rem;\n  --radius-5: 4rem;\n  --radius-6: 8rem;\n  --radius-round: 1e5px;\n  --radius-blob-1: 30% 70% 70% 30% / 53% 30% 70% 47%;\n  --radius-blob-2: 53% 47% 34% 66% / 63% 46% 54% 37%;\n  --radius-blob-3: 37% 63% 56% 44% / 49% 56% 44% 51%;\n  --radius-blob-4: 63% 37% 37% 63% / 43% 37% 63% 57%;\n  --radius-blob-5: 49% 51% 48% 52% / 57% 44% 56% 43%;\n  --radius-conditional-1: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-1));\n  --radius-conditional-2: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-2));\n  --radius-conditional-3: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-3));\n  --radius-conditional-4: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-4));\n  --radius-conditional-5: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-5));\n  --radius-conditional-6: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-6));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
