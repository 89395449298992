import React from 'react';
import style from './style.module.css';

const Spec = ({ spec, title }) => {
  return (
    <div className={`${style.specsHeader} ${style.specsInfo}`}>
      <div >
        {spec !=='2.0L' ?<div className={style.upTo}></div> : null}
        
        <h3 className={`spin  ${style.specsSpinner}`}>{spec}</h3>
      </div>
      <h3>{title}</h3>
    </div>
  );
};

export default Spec;
