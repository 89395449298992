import React from 'react';
import style from './style.module.css';
import { awd } from '../Images';
import Disclaimer from '../Disclaimer';

const SubHero = () => {
  return (
    <div className={`${style.subHero}`} id="leadInMobile">
      <div style={{ backgroundImage: `url(${awd})` }} className={`${style.subHeroImg}`}>
        <div
          className="disclaimer"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 32,
            height: 32,
            background: '#ffffff75',
            display: 'flex',
            justifyContent: 'center',
            color: 'black',
            zIndex: 1000
          }}>
          <Disclaimer disclaimerCode={291} black bottomLeft />
        </div>
      </div>
      <div className={`${style.subHeroContainer}`}>
        <div className={`${style.imageText}`}>
          <h2>OWN EVERY AVE</h2>
          <div className={`${style.titledPargragh}`}>
            <h3>Intelligent All-Wheel Drive</h3>
            <p>
              Sometimes, the road less traveled is the one most thrilling. Kicks available
              Intelligent All-Wheel Drive (AWD) system continuously monitors surface conditions and
              seamlessly transfers power from the front wheels to the rear to help enhance traction.{' '}
              <Disclaimer disclaimerCode={1323} top />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHero;
