// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.borders.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.media.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.sizes.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.widths.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css/props.zindex.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:before,
*:after {
  box-sizing: border-box;
}

#global-styles__root--bNoCs,
body,
button {
  font-family: var(--font-family-light);
  margin: 0;
}

:root {
  --border-gray: #aaadae;
  --sub-text: #5c5c5c;
  --light-gray-text: #f0ece8;
  --medium-gray-text: #767676;
  --dark-light-gray: #d2d2d2;
  --highsnobiet-gray: #efefef;
  --nissan-beige: #f0ece8;
  --nissan-black: #1e1e1e;
  --font-family-light: 'Nissan Light', 'Nissan Regular', Arial, Helvetica, verdana, sans-serif;
  --font-family-regular: 'Nissan Regular', 'Nissan Light', Arial, Helvetica, verdana, sans-serif;
}

button::after {
  font-family: icon-Nissan-Global;
}
`, "",{"version":3,"sources":["webpack://./public/global-styles.css"],"names":[],"mappings":"AAOA;;;EAGE,sBAAsB;AACxB;;AAEA;;;EAGE,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,0BAA0B;EAC1B,2BAA2B;EAC3B,0BAA0B;EAC1B,2BAA2B;EAC3B,uBAAuB;EACvB,uBAAuB;EACvB,4FAA4F;EAC5F,8FAA8F;AAChG;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["@import 'css/props.borders.css';\n@import 'css/props.fonts.css';\n@import 'css/props.media.css';\n@import 'css/props.sizes.css';\n@import 'css/props.widths.css';\n@import 'css/props.zindex.css';\n\n*,\n*:before,\n*:after {\n  box-sizing: border-box;\n}\n\n#root,\nbody,\nbutton {\n  font-family: var(--font-family-light);\n  margin: 0;\n}\n\n:root {\n  --border-gray: #aaadae;\n  --sub-text: #5c5c5c;\n  --light-gray-text: #f0ece8;\n  --medium-gray-text: #767676;\n  --dark-light-gray: #d2d2d2;\n  --highsnobiet-gray: #efefef;\n  --nissan-beige: #f0ece8;\n  --nissan-black: #1e1e1e;\n  --font-family-light: 'Nissan Light', 'Nissan Regular', Arial, Helvetica, verdana, sans-serif;\n  --font-family-regular: 'Nissan Regular', 'Nissan Light', Arial, Helvetica, verdana, sans-serif;\n}\n\nbutton::after {\n  font-family: icon-Nissan-Global;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `global-styles__root--bNoCs`
};
export default ___CSS_LOADER_EXPORT___;
