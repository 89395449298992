import React, { useState } from 'react';
import style from './style.module.css';
import Color from './Color';
import parse from 'html-react-parser';
import { triggerMKSEvent } from '../../../utilities/analyticsUtils'; 


const ColorPicker = ({ handleSwitchVariation, curentColor }) => {
  const colors = [
    {
      color: 'main',
      name: 'Scarlet Ember Tricoat / <br />Gun Metal Metallic',
      topColor: '#DF2727',
      bottomColor: '#707070'
    },
    {
      color: 'articBlue',
      name: 'Super Black / <br />Arctic Ice Blue Metallic',
      topColor: '#000000',
      bottomColor: '#39B1EF'
    },
    {
      color: 'yuzu',
      name: 'Super Black / <br />Yuzu Yellow Metallic',
      topColor: '#000000',
      bottomColor: '#ECFE16'
    },
    {
      color: 'aspen',
      name: 'Super Black / <br />Aspen White TriCoat',
      topColor: '#000000',
      bottomColor: '#FFFFFF'
    },
    {
      color: 'metallicBlack',
      name: 'Super Black / <br />Gun Metal Metallic',
      topColor: '#000000',
      bottomColor: '#B2B2B2'
    },
    {
      color: 'emberBlack',
      name: 'Super Black / <br />Scarlet Ember Tricoat',
      topColor: '#000000',
      bottomColor: '#DF2727'
    },
    {
      color: 'deepBlue',
      name: 'Gun Metal Metallic / <br />Deep Blue Pearl',
      topColor: '#707070',
      bottomColor: '#092464'
    },
    {
      color: 'canyonBronze',
      name: 'Canyon Bronze <br />Metallic',
      topColor: '#6B6243',
      bottomColor: '#6B6243'
    }
  ];
  const getColor = (colorCode) => {
    return colors.find((c) => c.color === colorCode);
  }

  const [color, setColor] = useState(curentColor);
  const [displayColorName, setDisplayColor] = useState(getColor(curentColor).name);
  const [mksTriggers, setMksTriggers] = useState([]);

  const switchColor = (newColor, newColorName) => {
    handleSwitchVariation(newColor);
    setColor(newColor);
    setDisplayColor(newColorName);

    // MKS Tagging
    const mksId = newColor;
    console.log('mks tagged', mksTriggers.includes(mksId));
    if (!mksTriggers.includes(mksId)) {
      triggerMKSEvent('trackClickInteraction', {
        interactionType: 'colour-chip',
        interactionValue: newColorName.replace('<br />', ''),
        locationInPage: 'pfa'  
      })
      setMksTriggers(triggers => [...triggers, mksId]);
    }
  };


  const displayColors = colors.map((c, index) => {
    return (
      <Color
        switchColor={switchColor}
        color={c.color}
        active={color.toLocaleUpperCase() === c.color.toLocaleUpperCase()}
        colorName={c.name}
        key={index + c.color}
        colorOneHash={c.topColor}
        colorTwoHash={c.bottomColor}
      />
    );
  });
  return (
    <div className={style.colorPicker}>
      <div style={color === 'aspen' || color === 'yuzu' ? { color: '#1E1E1E' } : { color: '#fff' }}>
        <p className={style.colorName}>{parse(displayColorName.toLocaleUpperCase())}</p>
      </div>
      <div className={style.colors}>{displayColors}</div>
      <div></div>
    </div>
  );
};

export default ColorPicker;
