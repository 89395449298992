import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import style from './style.module.css';
import HeroTimeline from '../../../data/hero_timeline.json';
import ImageBufferManager from '../../../managers/ImageBufferManager';
import TimeLineManager from '../../../managers/TimelineManager';
import ColorPicker from '../ColorPicker';
import Modal from '../../Modal';
import Disclaimer from '../../Disclaimer';
import { reveal } from '../../Images';


gsap.registerPlugin(ScrollTrigger);

const HeroContainer = ({ showHandraiser, loading, setDisplayOnLoad, deepLinkOpen }) => {


  const [variantName, setVariantName] = useState('main');
  const [imagesBuffer, setImagesBuffer] = useState({});
  const [varImagesBuffer, setVarImagesBuffer] = useState({});
  const currentFrame = useRef(0);
  const [clearFrame, setClearFrame] = useState(false);
  const [timeLineManager, setTimeLineManager] = useState(null);
  const [imageBufferManager, setImageBufferManager] = useState(null);
  const [showNotifier, setShowNotifier] = useState(true);

  const heroCanvasRef = useRef(null);
  const heroContainerRef = useRef(null);
  const variantNameRef = useRef('red');
  const timeLineManagerRef = useRef(null);
  const [trackOne, setTrackOne] = useState({ x: 0, y: 0 });
  const [trackTwo, setTrackTwo] = useState({ x: 0, y: 0 });
  const [trackThree, setTrackThree] = useState({ x: 0, y: 0 });
  const imageBufferManagerRef = useRef(null);
  const logFrames = true;


  const trackRef = useRef(null);

  const renderFrame = (canvas, buffer, frame, variation) => {
    const dpr = window.devicePixelRatio || 1;
    const context = canvas.getContext('2d');
    const frameImage = buffer.getBufferImage(frame, variation);

    // may have changed on mobile if rotated
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = canvas.offsetHeight * dpr;
    context.scale(dpr, dpr);

    if (clearFrame) {
      context.clearRect(0, 0, canvas.width, canvas.width);
    }

    context.drawImage(frameImage, 0, 0, canvas.width / dpr, canvas.height / dpr);
  };

  const logFrame = (timeline, buffer, frame, variantName) => {
    const image = buffer.getBufferImage(frame, variantName);
    const trackers = timeline.getTrackers(frame);

    setTrackOne(updateTrackingPos(trackers, 'shoe'));
    setTrackTwo(updateTrackingPos(trackers, 'torus'));
    setTrackThree(updateTrackingPos(trackers, 'speaker'));
  };

  const updateTrackingPos = (trackers, trackObj) => {
    const currentCanvas = heroCanvasRef.current;
    const height = currentCanvas.offsetHeight;
    const track = trackRef.current;
    // Set trackers to arbitrary large number to hide them when loading the page on colours
    let newY = 0;
    let newX = 0;
    if (trackers !== null && trackers[trackObj] !== null && trackers[trackObj] !== undefined) {
      track.style.height = height + 'px';
      track.style.width = height + 'px';
      newY = (trackers[trackObj].y / 1920) * height;
      newX = (trackers[trackObj].x / 1920) * height;
    }

    return { x: newX, y: newY };
  };

  const updateHero = (playhead) => {
    const frame = Math.round(playhead.frame);
    const currentVariantName = variantNameRef.current;
    const currentTimeline = timeLineManagerRef.current;
    const currentBuffer = imageBufferManagerRef.current;
    const currentCanvas = heroCanvasRef.current;
    if (currentTimeline === null) {
      return;
    }
    // console.log(frame);
    if (frame >= 61 && showNotifier) {
      setShowNotifier(false);
      trackRef.current.style.display = 'block';

      showHandraiser(true);
    } else {
      trackRef.current.style.display = 'none';
      setShowNotifier(true);
      showHandraiser(false);
    }

    if (currentFrame.current !== frame && currentTimeline.haveFrame(frame)) {
      currentFrame.current = frame;
      renderFrame(currentCanvas, currentBuffer, frame, currentVariantName);

      if (logFrames) {
        logFrame(currentTimeline, currentBuffer, frame, currentVariantName);
      }
    }
  };
  useEffect(() => {
    let deep = window.location.hash;
    const handRaiser = document.getElementById('handRaiser');
    const video = document.getElementById('designVideos');
    const bose = document.getElementById('spotify');
    const awd = document.getElementById('leadIn');
    const awdMobile = document.getElementById('leadInMobile');
    const connected = document.getElementById('connectedTech');

    if (deep && deep.toLowerCase() === '#design' && video) {
      setTimeout(() => {
        video.scrollIntoView();
      }, 500);
    } else if (bose && deep && deep.toLowerCase() === '#bose-audio') {
      setTimeout(() => {
        bose.scrollIntoView();
      }, 500);
    } else if (
      awdMobile &&
      window.innerWidth < 971 &&
      deep &&
      deep.toLowerCase() === '#intelligent-awd'
    ) {
      setTimeout(() => {
        awdMobile.scrollIntoView();
      }, 500);
    } else if (awd && deep && deep.toLowerCase() === '#intelligent-awd') {
      setTimeout(() => {
        awd.scrollIntoView();
      }, 500);
    } else if (connected && deep && deep.toLowerCase() === '#connected-technology') {
      setTimeout(() => {
        connected.scrollIntoView();
      }, 500);
    } else if (handRaiser && deep) {
      setTimeout(() => {
        handRaiser.scrollIntoView();
      }, 500);
    }
  }, []);

  useGSAP(() => {
    const frames = HeroTimeline['frames'];
    let playhead = { frame: currentFrame.current || -1 };
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroContainerRef.current,
        start: 'top top',
        end: 'bottom+=500px top',
        scrub: true,
        
      }
    });

    tl.to(playhead, {
      frame: Object.keys(frames).length - 2,
      ease: 'none',
      // duration: Object.keys(frames).length / fps
      onUpdate: (self) => updateHero(playhead)
    });

    imageBufferManager &&
      imageBufferManager.getVarients().then((buffer) => {
        setVarImagesBuffer(buffer);
      });
  }, [imagesBuffer]);

  const handleSwitchVariation = (newImageType) => {
    const currentBuffer = imageBufferManagerRef.current;
    const currentCanvas = heroCanvasRef.current;
    setVariantName(newImageType);
    renderFrame(currentCanvas, currentBuffer, currentFrame.current, newImageType);

  };

  // Update the ref whenever variantName changes
  useEffect(() => {
    variantNameRef.current = variantName;
  }, [variantName]);

  useEffect(() => {
    timeLineManagerRef.current = timeLineManager;
  }, [timeLineManager]);

  useEffect(() => {
    imageBufferManagerRef.current = imageBufferManager;
  }, [imageBufferManager]);

  // load the timeline, kick it all off
  useEffect(() => {
    const canvas = heroCanvasRef.current;
    const dpr = window.devicePixelRatio || 1;
    const context = canvas.getContext('2d');

    // may have changed on mobile if rotated
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = canvas.offsetHeight * dpr;
    context.scale(dpr, dpr);

    if (clearFrame) {
      context.clearRect(0, 0, canvas.width, canvas.width);
    }

    const image = new Image();
    image.src = reveal;
    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width / dpr, canvas.height / dpr);
    };
    const newTimeLineManager = new TimeLineManager(HeroTimeline);
    setTimeLineManager(newTimeLineManager);

    const newImageBufferManager = new ImageBufferManager(newTimeLineManager);
    setImageBufferManager(newImageBufferManager);

    newImageBufferManager.preloadImages().then((buffer) => {
      setImagesBuffer(buffer);
    });
  }, []);

  return (
    <div id="herocontainer" ref={heroContainerRef} className={style.heroContainer}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 32,
          height: 32,
          background: '#ffffff75',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
          zIndex: 1000
        }}>
        <Disclaimer disclaimerCode={'TK'} black topRight />
        <Disclaimer disclaimerCode={26} black topRight />
      </div>

      <div
        className={`${style.title} title`}
        style={
          variantName === 'aspen' || variantName === 'yuzu'
            ? { color: '#1E1E1E' }
            : { color: '#fff' }
        }>
        <h1 className={style.tagLine}>ALL-NEW 2025 NISSAN KICKS®</h1>
        <div className={style.heroHandraiser}>
          <div>
            DROPPING SUMMER 2024
            <Disclaimer disclaimerCode={'TKS'} topRight black/>
          </div>
          <Modal
            buttonText={'Notify Me'}
            loading={loading}
            setDisplayOnLoad={setDisplayOnLoad}
            displayOnLoad={deepLinkOpen}
          />
        </div>
      </div>
      <div
        ref={trackRef}
        style={{
          position: 'absolute',
          height: heroCanvasRef.height,
          width: heroCanvasRef.width,
          display: 'none'
        }}>
        
      </div>

      <div className={style.canvasFrame}>
        <canvas
          ref={heroCanvasRef}
          className={style.heroBuffer}
          alt="Animation Buffer 1"
          width={1920}
          height={1920}
        />
      </div>

      <div className={style.buttonsContainer}>
        <ColorPicker handleSwitchVariation={handleSwitchVariation} curentColor={variantName} />
      </div>

    </div>
  );
};

export default HeroContainer;
