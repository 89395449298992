import React, { useEffect, useState } from 'react';
import LandingPage from './page/LandingPage';
import { register } from 'swiper/element/bundle';

register();

const App = () => {
  const [loading, setLoading] = useState(false);
  const [deeplinkOpen, setOpenHandRaiser] = useState(false);


  useEffect(() => {
    let deeplink = window.location.hash

    if(deeplink && deeplink !== undefined) {
      setOpenHandRaiser(true)
      setLoading(false)
    }

  }, []);

  return (
    <div>
      <LandingPage loading={loading} setOpenHandraiser={setOpenHandRaiser} deeplinkOpen={deeplinkOpen}/>
    </div>
  );
};

export default App;
