import React from 'react';
import style from './Modal.module.css';
import { useState } from 'react';
import { triggerMKSEvent } from '../../utilities/analyticsUtils';
const Modal = ({ buttonText }) => {
  const [hidden, setHidden] = useState(true);
  const [mksTriggers, setMksTriggers] = useState([]);

  const handleClick = () => {
    // setHidden(false)
    const handRaiser = document.getElementById('handRaiser');
    if (handRaiser) {
      handRaiser.scrollIntoView();
    }

    // MKS Tagging
    const mksId = 'handraiser-modal';
    console.log('mks tagged', mksTriggers.includes(mksId));
    if (!mksTriggers.includes(mksId)) {
      triggerMKSEvent('trackClickInteraction', {
        interactionType: 'button',
        interactionValue: 'notify-me',
        locationInPage: 'nav'
      });
      setMksTriggers((triggers) => [...triggers, mksId]);
    }
  };


  return (
    <div>
      <div>
        <button onClick={handleClick} className={`${style.btn}`}>
          {buttonText}
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.75 10.9993L19.25 10.9993"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M13.75 16.5L19.25 11L13.75 5.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>

        {!hidden ? (
          <div className={style.handRaiserBackground} onClick={() => setHidden(true)}>
            <div className={style.handRaiser} onClick={(e) => e.stopPropagation()}>
              <button className={style.closeButton} onClick={() => setHidden(true)}>
                <svg
                  className={style.closeIcon}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1C3.34315 3.34315 7 7 7 7M13 13C10.6569 10.6569 7 7 7 7M7 7L13 1M7 7L1 13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className={style.formInfo}>
                <h2>Tell me more</h2>
                <p>
                  Drop your details to stay up to date on the launch of the All-New
                  Nissan&nbsp;Kicks.
                </p>
              </div>
              <div className={style.paceForm}>
                <iframe
                  src=""
                  className={style.paceIframe}></iframe>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
