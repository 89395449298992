// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(html) {
  --layer-1: 1;
  --layer-2: 2;
  --layer-3: 3;
  --layer-4: 4;
  --layer-5: 5;
  --layer-important: 2147483647;
}
`, "",{"version":3,"sources":["webpack://./public/css/props.zindex.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[":where(html) {\n  --layer-1: 1;\n  --layer-2: 2;\n  --layer-3: 3;\n  --layer-4: 4;\n  --layer-5: 5;\n  --layer-important: 2147483647;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
