// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FloatingHandraiser-module__handRaiser--UnjAn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-2);
  z-index: 5000;
  width: 100%;
  padding: var(--size-3) var(--size-4) var(--size-3);
  background: white;
  top: 0;
  position: fixed;
  border-bottom: 1px solid var(--dark-light-gray);
}

.FloatingHandraiser-module__textContainer--Iv0DM {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--font-letterspacing-1); /* 2% */
  line-height: var(--font-lineheight-0);

}

@media (min-width: 581px) {
  .FloatingHandraiser-module__handRaiser--UnjAn {
    padding: var(--size-2) var(--size-8) var(--size-3);
  }
}

@media (min-width: 768px) {
  .FloatingHandraiser-module__handRaiser--UnjAn {
    padding: var(--size-5) var(--size-10) var(--size-5) var(--size-3);
  }

  .FloatingHandraiser-module__textContainer--Iv0DM {
    font-size: var(--font-size-1);

    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.FloatingHandraiser-module__titleRegular--FC3IC {
  font-family: var(--font-family-regular);
  margin: 0;
}

.FloatingHandraiser-module__titleLight--oPkEp {
  font-family: var(--font-family-light);
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/FloatingHandraiser/FloatingHandraiser.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,kDAAkD;EAClD,iBAAiB;EACjB,MAAM;EACN,eAAe;EACf,+CAA+C;AACjD;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;EACjB,2CAA2C,EAAE,OAAO;EACpD,qCAAqC;;AAEvC;;AAEA;EACE;IACE,kDAAkD;EACpD;AACF;;AAEA;EACE;IACE,iEAAiE;EACnE;;EAEA;IACE,6BAA6B;;IAE7B,aAAa;IACb,WAAW;IACX,8BAA8B;EAChC;AACF;;AAEA;EACE,uCAAuC;EACvC,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,SAAS;AACX","sourcesContent":[".handRaiser {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: var(--size-2);\n  z-index: 5000;\n  width: 100%;\n  padding: var(--size-3) var(--size-4) var(--size-3);\n  background: white;\n  top: 0;\n  position: fixed;\n  border-bottom: 1px solid var(--dark-light-gray);\n}\n\n.textContainer {\n  margin: 0;\n  font-size: 14px;\n  line-height: 24px;\n  letter-spacing: var(--font-letterspacing-1); /* 2% */\n  line-height: var(--font-lineheight-0);\n\n}\n\n@media (min-width: 581px) {\n  .handRaiser {\n    padding: var(--size-2) var(--size-8) var(--size-3);\n  }\n}\n\n@media (min-width: 768px) {\n  .handRaiser {\n    padding: var(--size-5) var(--size-10) var(--size-5) var(--size-3);\n  }\n\n  .textContainer {\n    font-size: var(--font-size-1);\n\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n  }\n}\n\n.titleRegular {\n  font-family: var(--font-family-regular);\n  margin: 0;\n}\n\n.titleLight {\n  font-family: var(--font-family-light);\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"handRaiser": `FloatingHandraiser-module__handRaiser--UnjAn`,
	"textContainer": `FloatingHandraiser-module__textContainer--Iv0DM`,
	"titleRegular": `FloatingHandraiser-module__titleRegular--FC3IC`,
	"titleLight": `FloatingHandraiser-module__titleLight--oPkEp`
};
export default ___CSS_LOADER_EXPORT___;
