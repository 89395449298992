// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style-module__open--oyjAV {
    color: white;
    font-size: 40px;
    border: none;
    background-color: rgba(0,0,0,0);
    font-family: 'Nissan Light';
}
.style-module__drawer--rovkA {
    border-bottom: 1px solid;
}

.style-module__drawerHeader--Q0Pe_ {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
}

.style-module__drawerHeader--Q0Pe_ > h3{
    font-size: 25px;
    line-height: var(--font-lineheight-1);
    color: #fff;
}


.style-module__drawerHeader--Q0Pe_ button {
    font-size: var(--font-size-8);
    font-weight: var(--font-weight-2);
}

.style-module__vibesDrawer--DooYF {
    /* width: 100vw; */
    grid-column: span 2;
    max-width: var(--width-lg);
    margin: auto;
    padding: 0 var(--size-3) var(--size-8);
}

.style-module__vibesDrawer--DooYF p {
    font-size: var(--font-size-3);
    line-height: var(--font-lineheight-3);
    margin: var(--size-3) 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Drawer/style.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,+BAA+B;IAC/B,2BAA2B;AAC/B;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,qCAAqC;IACrC,WAAW;AACf;;;AAGA;IACI,6BAA6B;IAC7B,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,0BAA0B;IAC1B,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,6BAA6B;IAC7B,qCAAqC;IACrC,uBAAuB;AAC3B","sourcesContent":["\n.open {\n    color: white;\n    font-size: 40px;\n    border: none;\n    background-color: rgba(0,0,0,0);\n    font-family: 'Nissan Light';\n}\n.drawer {\n    border-bottom: 1px solid;\n}\n\n.drawerHeader {\n    display: flex;\n    justify-content: space-between;\n    font-size: 25px;\n}\n\n.drawerHeader > h3{\n    font-size: 25px;\n    line-height: var(--font-lineheight-1);\n    color: #fff;\n}\n\n\n.drawerHeader button {\n    font-size: var(--font-size-8);\n    font-weight: var(--font-weight-2);\n}\n\n.vibesDrawer {\n    /* width: 100vw; */\n    grid-column: span 2;\n    max-width: var(--width-lg);\n    margin: auto;\n    padding: 0 var(--size-3) var(--size-8);\n}\n\n.vibesDrawer p {\n    font-size: var(--font-size-3);\n    line-height: var(--font-lineheight-3);\n    margin: var(--size-3) 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"open": `style-module__open--oyjAV`,
	"drawer": `style-module__drawer--rovkA`,
	"drawerHeader": `style-module__drawerHeader--Q0Pe_`,
	"vibesDrawer": `style-module__vibesDrawer--DooYF`
};
export default ___CSS_LOADER_EXPORT___;
