/* eslint-disable no-undef */

/**
 * Function for triggering an MKS event.
 * @private
 * @param {String} action the event action
 * @param {Object} event the event payload
 */
export const triggerMKSEvent = (action, event) => {
  if (!action) {
    console.warn('Invalid Action. Must be a valid action string.', action);
    return false;
  }
  // TODO test for dev vs prod?
  // if (_isAppRunningInSandboxMode) {
  //   return;
  // }
  // logger.log('Firing Event::', action);
  if (!event) {
    console.warn('Invalid Event. Must be a valid event.', event);
  }
  if (typeof window !== 'undefined' && window && window._satellite) {
    try {
      window._satellite.track(action, event);
    } catch (e) {
      console.warn('There was a problem calling window._satellite.track:', e);
    }
  }
};

