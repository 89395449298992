import React from 'react';
import style from './FloatingHandraiser.module.css';
import Modal from '../Modal';
import Disclaimer from '../Disclaimer';

const FloatingHandraiser = ({loading}) => {

  return (
    <div>
      <div className="pin">
        <div className={`${style.handRaiser} handRaiser`}>
          <div className={style.textContainer}>
            <p className={style.titleRegular}>2025 NISSAN KICKS®</p>
            <p className={style.titleLight}>
              DROPPING SUMMER&nbsp;2024<Disclaimer disclaimerCode={'TKS'} black/>
            </p>
          </div>

          <div className={style.linkoutOveride}>
            <Modal buttonText={'Notify Me'} loading={loading}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingHandraiser;
