import React, { useState } from 'react';
import style from './style.module.css';
import Disclaimer from '../Disclaimer';
import { triggerMKSEvent } from '../../utilities/analyticsUtils'; 

const DrawerItem = ({ title, paragraph, open, id, openDrawer, disclaimer }) => {
  const [mksTriggers, setMksTriggers] = useState([]);

  const handleClick = () => {
    openDrawer(open !== id ? id : 0);

    // MKS Tagging
    const mksId = title;
    if (!mksTriggers.includes(mksId)) {
      triggerMKSEvent('trackAccordionInteraction', {
        accordionType: 'open',
        accordionText: title 
      })
      setMksTriggers(triggers => [...triggers, mksId]);
    }
  }
  const claims = disclaimer.map((claim, index) => {
    return <Disclaimer disclaimerCode={claim} key={`index${claim}`} />;
  });

  return (
    <div className={style.drawer}>
      <div className={style.drawerHeader}>
        <h3>{title}</h3>
        <button className={style.open} onClick={handleClick}>
          {open === id ? '-' : '+'}
        </button>
      </div>

      <p hidden={open !== id}>
        {paragraph} {claims}
      </p>
    </div>
  );
};

export default DrawerItem;
