import React, { useRef, useEffect } from 'react';
import style from './style.module.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import Spec from './Spec';
import Disclaimer from '../Disclaimer';
import { performace } from '../Images';
import { useInView } from 'react-intersection-observer';
import { triggerMKSEvent } from '../../utilities/analyticsUtils';

gsap.registerPlugin(ScrollTrigger);

const Specs = ({ specsRef, timeLine }) => {

  const [ specsMksRef ] = useInView({
    onChange: (inView) => { 
      if (inView) {
        triggerMKSEvent('trackClickInteraction', {
          interactionType: 'scroll',
          interactionValue: 'Specs (3/5)',
          locationInPage: '2025-kicks-reveal'
        })
      }
    },
    triggerOnce: true,
    threshold: 0,
  });


  useGSAP(() => {
    const specs = gsap.utils.toArray('.spin').forEach((spec) => {
      if (spec.textContent === '2.0L') {
        timeLine &&
          timeLine.fromTo(
            spec,
            { textContent: '0' },
            {
              textContent: spec.textContent,
              ease: 'power2.out'
            },
            0
          );
      } else {
        timeLine &&
          timeLine.fromTo(
            spec,
            { textContent: '0' },
            {
              textContent: spec.textContent,
              ease: 'power4.in',
              snap: {
                textContent: 0.1
              }
            },
            0
          );
      }
    });
  }, [timeLine]);
  return (
    <div
      className={`${style.container}`}
      id="specs"
      ref={specsRef}
      style={{ backgroundImage: `url(${performace})`, position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 32,
          height: 32,
          background: '#ffffff75',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
          zIndex: 1000
        }}>
        <Disclaimer disclaimerCode={291} black topRight />
      </div>

      <div className={style.specs} ref={specsMksRef}>
        <div className={style.specsHeader}>
          <h2 className="header">STRAP IN AND ROLL OUT.</h2>
          <div className={style.titledPargragh}>
            <h2>Performance</h2>
            <p>
              Power past expectations in this compact crossover featuring a powerful new 2.0L
              4-cylinder engine that can really kick it.
            </p>
          </div>
        </div>
        <Spec title={'4-CYLINDER ENGINE'} spec={'2.0L'} />
        <Spec title={'HORSEPOWER'} spec={'141'} />
        <Spec title={'LB-FT OF TORQUE'} spec={'140'} />
      </div>
    </div>
  );
};

export default Specs;
