import React from 'react';
import style from './style.module.css';
import mica from '../../../../public/assets/mica.webp';

const Color = ({ switchColor, color, active = false, colorName, colorOneHash, colorTwoHash }) => {
  return (
    <div className={active ? style.activeBorder : style.inactiveBorder}>
      <div
        className={style.color}
        tabIndex="0"
        onKeyDown={(e) => {
          e.key === 'Enter' ? switchColor(color, colorName) : null;
        }}>
        <button onClick={() => switchColor(color, colorName)} tabIndex="-1">
          <svg
            className={style.sheen}
            width="37"
            height="62"
            viewBox="0 0 37 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.3238 -1.79118C64.338 -6.08324 57.91 -9.58392 49.9896 -9.61026L31.3215 -9.67236C31.3215 -9.67236 35.6088 -3.44098 26.9308 15.1815C18.2527 33.804 0.971556 48.5122 0.927612 61.7235L49.7518 61.8859C57.6722 61.9122 64.1234 58.4544 64.1376 54.1623L64.3238 -1.79118Z"
              fill="url(#paint0_linear_5568_18023)"
              fillOpacity="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5568_18023"
                x1="64.1221"
                y1="58.8251"
                x2="-3.37006"
                y2="58.6006"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0" />
                <stop offset="1" stopColor="white" />
              </linearGradient>
            </defs>
          </svg>

          <img src={mica} className={style.overLay} alt="Added metalic texture" />
          <div className={style.overLay}></div>
          <div className={style.colorOne} style={{ backgroundColor: colorOneHash }}></div>
          <div className={style.colorTwo} style={{ backgroundColor: colorTwoHash }}></div>
        </button>
      </div>
    </div>
  );
};

export default Color;
