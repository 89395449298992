import React, { useState } from 'react';
import gsap from 'gsap';
import style from './style.module.css';
import DrawerItem from './DrawerItem';

const Drawer = () => {
  const [drawer, setDawer] = useState(1);
  return (
    <div className={style.vibesDrawer}>
      <DrawerItem
        id={1}
        openDrawer={setDawer}
        open={drawer}
        title="Dual 12.3-inch Displays"
        disclaimer={[]}
        paragraph='Say hello to the available redesigned dual 12.3" displays. They’re fully customizable and flow together to give you one seamless viewing experience.'
      />

      <DrawerItem
        id={2}
        openDrawer={setDawer}
        open={drawer}
        title="Wireless Smartphone Connectivity"
        paragraph="Discover a world of convenience with the powerful NissanConnect® system as you seamlessly navigate, call, and text hands-free with available wireless Apple CarPlay® with your compatible iPhone and wireless Android Auto™ on a compatible device."
        disclaimer={[48, 1189, 250]}
      />

      <DrawerItem
        id={3}
        openDrawer={setDawer}
        open={drawer}
        title="Wireless Charging"
        paragraph="Place your compatible smartphone on the available wireless charging pad, and Kicks does the rest. An indicator light will let you know when your smartphone is charging."
        disclaimer={[48]}
      />
    </div>
  );
};

export default Drawer;
