class ImageBufferManager {
  constructor(timelineManager) {
    this.timeline = timelineManager;
    this.imagesBuffer = {};
  }

  preloadImages() {
    const promises = [];
    const frames = this.timeline.getFrames();
    Object.keys(frames).forEach((frameKey) => {
      const frameImages = this.timeline.getImages(frameKey);
      if (!frameImages) {
        return;
      }

      this.imagesBuffer[frameKey] = this.imagesBuffer[frameKey] || {};

      Object.keys(frames[frameKey]['images']).forEach((variationKey) => {
        if (variationKey !== 'main') {
          return;
        }
        const imageVariation = this.timeline.getImageVariation(frameKey, variationKey);
        if (!imageVariation) {
          return;
        }

        // let imageUrl = `./2025-kicks/media/hero/${variationKey}/${imageVariation['src']}`;

        let imageUrl = `./media/hero/${variationKey}/${imageVariation['src']}`;
        let image = new Image();
        image.src = imageUrl;
        promises.push(
          new Promise((resolve) => {
            image.onload = () => resolve();
          })
        );
        this.imagesBuffer[frameKey][variationKey] = image;
      });
    });

    return Promise.all(promises).then(() => this.imagesBuffer);
  }

  getVarients() {
    const promises = [];
    const frames = this.timeline.getFrames();
    Object.keys(frames).forEach((frameKey) => {
      const frameImages = this.timeline.getImages(frameKey);
      if (!frameImages) {
        return;
      }

      this.imagesBuffer[frameKey] = this.imagesBuffer[frameKey] || {};

      Object.keys(frames[frameKey]['images']).forEach((variationKey) => {
        if (variationKey === 'main') {
          return;
        }
        const imageVariation = this.timeline.getImageVariation(frameKey, variationKey);
        if (!imageVariation) {
          return;
        }

        // let imageUrl = `./2025-kicks/media/hero/${variationKey}/${imageVariation['src']}`;

        let imageUrl = `./media/hero/${variationKey}/${imageVariation['src']}`;
        let image = new Image();
        image.src = imageUrl;

        promises.push(
          new Promise((resolve) => {
            image.onload = () => resolve();
          })
        );
        this.imagesBuffer[frameKey][variationKey] = image;
      });
    });

    return Promise.all(promises).then(() => this.imagesBuffer);
  }

  getBufferImage(frame, variation) {
    return (
      this.imagesBuffer?.[frame]?.[variation] || this.imagesBuffer?.[frame]?.['main'] || new Image()
    );
  }
}

export default ImageBufferManager;
