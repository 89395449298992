import React from 'react';
import style from './style.module.css';
import Disclaimer from '../Disclaimer';
import parse from 'html-react-parser';

const Slide = ({ title, subTitle, paragraph, image, slider, disclaimer = [], altText, seconPara, midtextDisclaimer}) => {
  const claims = disclaimer.map((claim, index) => {
    return <Disclaimer disclaimerCode={claim} key={`inde x${claim}`} black/>;
  });

  return (
    <div className={`${style.slider} ${slider}`}>
      <div className={style.sectionInfo}>
        <h2>{parse(title)}</h2>
        <div className={style.titledPargragh}>
          <h3>{subTitle}</h3>
          <p>
            {paragraph}{midtextDisclaimer !== undefined ? <Disclaimer disclaimerCode={midtextDisclaimer} black/> : null}{seconPara}{claims}
          </p>
        </div>
      </div>
      <div className={style.frame}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 32,
          height: 32,
          background: '#ffffff75',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
          zIndex: 1000
        }}>
         <Disclaimer disclaimerCode={291} black top={window.innerWidth > 780 ? true :  false} topRight={window.innerWidth < 780 ? true :  false}/>
      </div>
        <img src={image} alt={altText}/>
      </div>
    </div>
  );
};

export default Slide;
