import React, { useState, useRef } from 'react';
import style from './Disclaimer.module.css';
import OutsideClickHandler from 'react-outside-click-handler';


const Disclaimer = ({
  disclaimerCode,
  black = false,
  top = false,
  topRight = false,
  bottomLeft = false
}) => {
  const [show, setShow] = useState(false);
  const toolTip = useRef(null);


  const disclaimers = {
    1323: 'Intelligent All-Wheel Drive cannot prevent collisions or provide enhanced traction in all conditions. Always monitor traffic and weather conditions.',
    233: 'Bose® is a registered trademark of The Bose Corporation. Centerpoint® is a registered trademark of The Bose Corporation. Personal® is a registered trademark of The Bose Corporation. PersonalSpace™ and UltraNearfield™ are trademarks of The Bose Corporation.',
    26: 'Nissan has taken care to ensure that the digital color swatches presented here are the closest possible representation of actual vehicle colors. Swatches may vary slightly due to viewing light or screen quality. Please see the actual vehicle and colors at your dealer.',
    'TK': 'Pre-production model shown. Actual production model may vary. SR model shown. More colors available. Please see the actual vehicle and colors at your dealer. 2025 Nissan Kicks not yet available for purchase. Expected availability Summer 2024. ',
    48: 'Driving is serious business and requires your full attention. If you have to use the connected device while driving, exercise extreme caution at all times so full attention may be given to vehicle operation. Smartphone or other external device not included.',
    291: 'Pre-production model shown. Actual production model may vary.',
    1189: ` Feature availability is dependent on vehicle model, trim level, packaging and options. Trial included with vehicle purchase. Consumer activation of NissanConnect Services package required. Compatible connected device and wireless network may be required. Refer to connected device’s owner’s manual for details. Late availability for some features. Driving is serious business and requires your full attention. Only use features and connected devices when safe and legal to do so. Some features, including automatic crash notification and SOS, are dependent upon the telematics device being in operative condition, its ability to connect to a wireless network, compatible wireless network availability, navigation map data and GPS satellite signal receptions, the absence of which can limit or prevent the ability to reach Customer Care or receive support. GPS mapping may not be detailed in all areas or reflect current road status. Never program GPS while driving. Only use Remote Engine Start and Remote Horn features in accordance with any laws, rules or ordinances in effect in your vehicle’s location. Cellular network not available in Guam, Puerto Rico, U.S. Virgin Islands, Saipan or American Samoa.
    Some services and features are provided by and dependent on third party service providers and not Nissan. Should service provider terminate or restrict service or features, service or features may be suspended or terminated without notice or with no liability to Nissan or its partners or agents. Services and features may require compatible cellular network provided by independent companies not within Nissan or its partners’ or agents’ control. Cellular network signal strength may vary and may not be available in all areas or at all times. Services and features may not function if cellular network is unavailable, restricted, or terminated. Nissan and its partners or agents are not responsible for associated costs or other third party changes that may be required for continued operation due to cellular network unavailability, restriction, or termination (including equipment replacements/upgrades, if available, or roaming charges on alternative networks). Technology is evolving, and changes by independent companies are not within Nissan’s or its partners’ or agents’ control. 
    Enrollment, owner consent, personal identification number (PIN), and subscription agreement may be required to receive full suite of features and services. Trial periods (if applicable) begin on the date of vehicle purchase or lease of a new Nissan. Trial periods and feature availability may be subject to change at any time and may be subject to early termination without notice. Required subscriptions may be sold separately for each available feature or service after trial period ends, and may continue until you call service provider to cancel. Installation costs, one-time activation fee, other fees and taxes may apply. Fees and programming subject to change. Feature may be subject to age restrictions in some areas. Subscriptions governed by service provider’s subscription agreement, terms and conditions and privacy statements available at service provider’s website. Text rates or data usage may apply. NissanConnect with WiFi terms and conditions of subscriber agreement apply. Trial is included with vehicle purchase on equipped new Nissan vehicles. Once your vehicle has exceeded the coverage parameters of the roadside assistance benefits included with your vehicle purchase, you will be responsible for any charges incurred by obtaining roadside assistance for your vehicle. For complete information concerning warranty coverage, conditions and exclusions, please see your Nissan dealer and read the warranty information booklet.
    The Nissan names, logos and slogans are trademarks owned by or licensed to Nissan Motor Co. Ltd. or its North American subsidiaries. Other trademarks and trade names are those of their respective owners.
    For important safety information, system limitations, and additional operating and feature information, see dealer, owner’s manual, or www.NissanUSA.com/connect/legal. `,
    250: `Google, Android, Android Auto, YouTube Music and other marks are trademarks of Google LLC. Google Assistant and some related features are not available in all languages or countries. See g.co/assistant/carlanguages for updates to language and country availability. To wirelessly use Android Auto on your car display, you need a compatible Android smartphone with an active data plan. You can check which smartphones are compatible at g.co/androidauto/requirements. To use Android Auto on your car display, you’ll need an Android phone running Android 8 or higher, an active data plan, and the Android Auto app.`,
    1176: `ProPILOT Assist, ProPILOT Assist 1.1, and ProPILOT Assist with Navi-link cannot prevent collisions. It is the driver’s responsibility to be in control of the vehicle at all times. Always monitor traffic conditions and keep both hands on the steering wheel. System operates only when lane markings are detected. Does not function in all weather, traffic and road conditions. System has limited control capability and the driver may need to steer, brake or accelerate at any time to maintain safety. See Owner’s Manual for safety information.`,
    1117: `Intelligent Around View® Monitor cannot eliminate blind spots and may not detect every object. Driver should always turn and check surroundings before driving. See Owner’s Manual for safety information.`,
    'TKS': ' 2025 Nissan Kicks not yet available for purchase. Expected availability Summer 2024. ',
    1381: 'Nissan Safety Shield technologies can’t prevent all collisions or warn in all situations. See Owner’s Manual for important safety information'
  };

  return (
    <span className={style.toolTipHolder} ref={toolTip}>

      <button
        className={style.disclaimer}
        onClick={() => setShow(true)}
        style={black ? { color: 'black' } : {}}>
        [*]
      </button>
      <OutsideClickHandler     onOutsideClick={() => {
        if(show) {
          setShow(false)
        }

      }}>
      <div
        className={`${show ? style.displayTooTip : style.hideToolTip} ${style.toolTip}`}
        style={
          top
            ? { bottom: '115%', top: 'auto', right: '-6.3rem' }
            : topRight
              ? { bottom: '115%', top: 'auto', right: '-10.3rem' }
              : bottomLeft
                ? { right: '-10.45rem' }
                : null
        }>
          
        <button className={`${style.disclaimer} ${style.exit}`} onClick={() => setShow(false)}>
          <svg
            className={style.closeIcon}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1C3.34315 3.34315 7 7 7 7M13 13C10.6569 10.6569 7 7 7 7M7 7L13 1M7 7L1 13"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div>{disclaimers[disclaimerCode]}</div>
        <span
          className={
            top
              ? style.toolTipArrowTop
              : topRight
                ? style.toolTipTopRight
                : bottomLeft
                  ? style.toolTipArrowBottomLeft
                  : style.toolTipArrow
          }></span>
      </div>
      </OutsideClickHandler>
    </span>
  );
};

export default Disclaimer;
